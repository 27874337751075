.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  background: #000000;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.table-cell {
  border: 1px solid #BBBBBB;
  height: 4em;
}